<template>
	<div id="dialog">
		<h3>{{ capitalize(labelCategory) }} Types</h3>
		<div>
			<ol>
				<li v-if="labelCategory == 'label'">
					<b>Subject</b><br /><i>What</i> is being depicted:<br />
					<div id="label-wrap">
						<BaseLabel :label="{ value: 'lillies' }" />
						<BaseLabel :label="{ value: 'nude' }" />
						<BaseLabel :label="{ value: 'hat' }" />
					</div>
				</li>

				<li v-if="labelCategory == 'label'">
					<b>Style</b><br />The artworks' <i>style</i>, <i>movement</i> or <i>period</i>:<br />
					<div id="label-wrap">
						<BaseLabel :label="{ value: 'abstract' }" />
						<BaseLabel :label="{ value: 'impressionism' }" />
						<BaseLabel :label="{ value: 'medieval' }" />
					</div>
				</li>

				<li>
					<b>Medium</b><br />The <i>materials</i> used:<br />
					<div id="label-wrap">
						<BaseLabel :label="{ value: 'oil' }" />
						<BaseLabel :label="{ value: 'acrylic' }" />
						<BaseLabel :label="{ value: 'plaster' }" />
					</div>
				</li>

				<li>
					<b>Substrate</b><br />The <i>base material</i>:<br />
					<div id="label-wrap">
						<BaseLabel :label="{ value: 'canvas' }" />
						<BaseLabel :label="{ value: 'wood panel' }" />
						<BaseLabel :label="{ value: 'metal armature' }" />
					</div>
				</li>
			</ol>
		</div>
		<div class="buttons-wrap">
			<FormButton value="Ok" @click="doCancel" />
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'
import BaseLabel from '@/components/BaseLabel'

// Internal
import { capitalize } from '@/use/StringMagic'

export default {
	name: 'DialogLabelTypes',
	components: { FormButton, BaseLabel },
	setup() {
		return { capitalize }
	},
	data() {
		return {
			// Defaults
			defaultParams: {
				labelCategory: 'label', // label / material - This defines which label types are listed.
			},
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['params', 'doCancel']),

		labelCategory() {
			return this.params.labelCategory || this.defaultParams.labelCategory
		},
	},
}
</script>

<style scoped lang="scss">
#dialog li {
	margin-bottom: 0.1rem;
}
#dialog:deep(.label) {
	margin: 0 0.05rem 0.05rem 0;
}
#label-wrap {
	display: inline-block;
	margin-top: 0.05rem;
}
</style>
